console.log('top');
var mainSwiper = new Swiper('.mainSwiper', {
  speed: 1500,
  slidesPerView: 1,
  autoplay: {
    delay: 4000
  },
  loop: true,
  effect: 'fade',
  allowTouchMove: true
});
mainSwiper.autoplay.stop();

window.addEventListener('load', function(){
  setTimeout(() => {
    document.querySelector('.animeImg1').classList.add('show');
  }, "0")
  setTimeout(() => {
    document.querySelector('.animeImg2').classList.add('show');
  }, "5000")
  setTimeout(() => {
    document.querySelector('.animeImg3').classList.add('show');
  }, "10000")
  setTimeout(() => {
    document.querySelector('.animeImg4').classList.add('show');
  }, "15000")
  setTimeout(() => {
    document.querySelector('.mainAnime').classList.add('out');
  }, "20000")
  setTimeout(() => {
    document.querySelector('.mainAnime').classList.add('hdn');
    document.querySelector('main').classList.add('show');
  }, "22000")
  setTimeout(() => {
    document.querySelector('.main_wrap').classList.add('cut1');
  }, "23000")
  setTimeout(() => {
    document.querySelector('.main_wrap').classList.add('cut2');
  }, "25000")
  setTimeout(() => {
    document.querySelector('.main_wrap').classList.add('cut3');
    mainSwiper.autoplay.start()
  }, "27000")
});

const skip = document.getElementById('skip');
skip.addEventListener('click', skipFunc);

const keyName = 'visited';
const keyValue = true;

if (!localStorage.getItem(keyName)) {
  localStorage.setItem(keyName, keyValue);
} else {
  skipFunc();
};


function skipFunc(){
  setTimeout(() => {
    document.querySelector('.mainAnime').classList.add('out');
  }, "0")
  setTimeout(() => {
    document.querySelector('.mainAnime').classList.add('hdn');
    document.querySelector('main').classList.add('show');
  }, "2000")
  setTimeout(() => {
    document.querySelector('.main_wrap').classList.add('cut1');
  }, "3000")
  setTimeout(() => {
    document.querySelector('.main_wrap').classList.add('cut2');
  }, "5000")
  setTimeout(() => {
    document.querySelector('.main_wrap').classList.add('cut3');
    mainSwiper.autoplay.start()
  }, "7000")
}