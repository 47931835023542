$(function(){
  var slider = ".mrSld";
  var thumbnailItem = ".mrSld_tmb .sld";

  $(thumbnailItem).each(function(){
    var index = $(thumbnailItem).index(this);
    $(this).attr("data-index",index);
  });

  $(slider).on('init',function(slick){
    var index = $(".slide-item.slick-slide.slick-current").attr("data-slick-index");
    $(thumbnailItem+'[data-index="'+index+'"]').addClass("active");
  });

  $(slider).slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    dots:false,
    arrows:true,
    fade: true,
    infinite: true,
    adaptiveHeight: true,
    prevArrow: '<div class="arrow prev_arrow"></div>',
    nextArrow: '<div class="arrow next_arrow"></div>',
  });
  $(thumbnailItem).on('click',function(){
    var index = $(this).attr("data-index");
    $(slider).slick("slickGoTo",index,false);
  });

  $(slider).on('beforeChange',function(event,slick, currentSlide,nextSlide){
    $(thumbnailItem).each(function(){
      $(this).removeClass("active");
    });
    $(thumbnailItem+'[data-index="'+nextSlide+'"]').addClass("active");
  });
});