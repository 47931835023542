var pageId = '';

pageId = document.querySelector('body').getAttribute('id');
switch (pageId) {
	//body id='top'
	case 'top':
		require('./top.ts');
	break;

	//body id='map'
	case 'location':
    $('.if_tab').on('click', function(){
      var index = $('.if_tab').index(this);
      $('.if_tab').removeClass('active');
      $('.info_tab_body').removeClass('active');
      $(this).addClass('active');
      $('.info_tab_body').eq(index).addClass('active');
    });
	break;

  //body id='access'
	case 'access':
    $('.sec1_tab').on('click', function(){
      var index = $('.sec1_tab').index(this);
      $('.sec1_tab').removeClass('active');
      $('.sec1_tab_body').removeClass('active');
      $(this).addClass('active');
      $('.sec1_tab_body').eq(index).addClass('active');
    });
	break;

  //body id='type'
	case 'type':
    typeShow();
	break;

  //body id='quality'
	case 'quality':
    $('.tab').on('click', function(){
      var index = $('.tab').index(this);
      $('.tab').removeClass('active');
      $('.sec').removeClass('active');
      $(this).addClass('active');
      $('.sec').eq(index).addClass('active');
    });
	break;

    //body id='quality'
	case 'modelroom':
		require('./mr.ts');
	break;

  //body id='limited'
  case 'limited':
    typeShow();
	break;
};

//common
require('./anime.ts');

window.addEventListener('load',function(){
  document.querySelector('main').classList.add('show');
});


const header = document.querySelector('header');
window.addEventListener('scroll',function(){
  var sH = window.scrollY;
  if(sH >= 100){
    header.classList.add('hScroll');
  }else{
    header.classList.remove('hScroll');
  }
});

const gBtn = document.querySelector('.g_menu_btn');
const gNav = document.querySelector('.g_nav');
gBtn.addEventListener('click',function(){
  this.classList.toggle('active');
  gNav.classList.toggle('active');
});

const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++){
  smoothScrollTrigger[i].addEventListener('click', (event) => {
    event.preventDefault();
    let href = smoothScrollTrigger[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const gap = 0;
    const target = rect + offset - gap;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
}


function typeShow(){
  window.addEventListener('DOMContentLoaded', function(){
    var urlParam = location.search.substring(1);
    if(urlParam) {
      var param = urlParam.split('&');
      var paramArray = [];
      for (i = 0; i < param.length; i++) {
        var paramItem = param[i].split('=');
        paramArray[paramItem[0]] = paramItem[1];
      }
      if (paramArray.id == 'type_A') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_A').classList.add('show');
      };
      if (paramArray.id == 'type_B1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_B1').classList.add('show');
      };
      if (paramArray.id == 'type_B1_menu1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_B1_menu1').classList.add('show');
      };
      if (paramArray.id == 'type_B1_menu2') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_B1_menu2').classList.add('show');
      };
      if (paramArray.id == 'type_B2_menu1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_B2_menu1').classList.add('show');
      };
      if (paramArray.id == 'type_D') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_D').classList.add('show');
      };
      if (paramArray.id == 'type_Dg') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_Dg').classList.add('show');
      };
      if (paramArray.id == 'type_D_menu1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_D_menu1').classList.add('show');
      };
      if (paramArray.id == 'type_D_menu2') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_D_menu2').classList.add('show');
      };
      if (paramArray.id == 'type_E_menu1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_E_menu1').classList.add('show');
      };
      if (paramArray.id == 'type_Jr') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_Jr').classList.add('show');
      };
      if (paramArray.id == 'type_C1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_C1').classList.add('show');
      };
      if (paramArray.id == 'type_C1_menu1') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_C1_menu1').classList.add('show');
      };
      if (paramArray.id == 'type_C1g') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_C1g').classList.add('show');
      };
      if (paramArray.id == 'type_Gr') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_Gr').classList.add('show');
      };
      if (paramArray.id == 'type_Hr') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_Hr').classList.add('show');
      };
      if (paramArray.id == 'type_F') {
        document.querySelector('.type').classList.remove('show');
        document.querySelector('#type_F').classList.add('show');
      };
    };
  });
}